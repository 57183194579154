import { Action, createReducer, on } from '@ngrx/store';
import { AppGlobal } from './store.global';
import { appStoreGlobalActions } from './store.global.action';
import { appStoreGlobalIS } from './store.global.state';

const appStoreGlobalRegister = createReducer(
  appStoreGlobalIS,

  on(appStoreGlobalActions.setUserAction, (state, { user }) => ({
    ...state,
    user,
  })),

  on(
    appStoreGlobalActions.setCollaboratorAction,
    (state, { collaborator }) => ({
      ...state,
      collaborator,
      document: `${collaborator?.agentCode}`,
      role: collaborator?.roleCode,
      isSalesforce: collaborator?.roleCode === '1' || collaborator?.roleCode === '2',
    })
  ),

  on(appStoreGlobalActions.setCompanyAction, (state, { company }) => ({
    ...state,
    company,
  })),

  on(appStoreGlobalActions.setIsLoadingAction, (state, { isLoading }) => ({
    ...state,
    isLoading,
  })),

  on(appStoreGlobalActions.setIsSavingAction, (state, { isSaving }) => ({
    ...state,
    isSaving,
  })),

  on(appStoreGlobalActions.setScreenIdAction, (state, { screenId }) => ({
    ...state,
    screenId,
  })),

  on(appStoreGlobalActions.setTitleAction, (state, { title }) => ({
    ...state,
    title,
  })),

  on(appStoreGlobalActions.setSubtitleAction, (state, { subtitle }) => ({
    ...state,
    subtitle,
  })),

  on(
    appStoreGlobalActions.setScreenSizeAction,
    (state, { isWeb, isSmall, isPortrait, isMedium, isLarge }) => ({
      ...state,
      isWeb,
      isSmall,
      isPortrait,
      isMedium,
      isLarge,
    })
  ),

  on(appStoreGlobalActions.setInnerHeightAction, (state, { innerHeight }) => ({
    ...state,
    innerHeight,
  })),

  on(appStoreGlobalActions.setInnerWidthAction, (state, { innerWidth }) => ({
    ...state,
    innerWidth,
  })),

  on(appStoreGlobalActions.setConnectedAction, (state, { connected }) => ({
    ...state,
    connected,
  })),

  on(
    appStoreGlobalActions.setAppointmentsToSyncAction,
    (state, { appointmentsToSync }) => ({
      ...state,
      appointmentsToSync,
    })
  ),

  on(
    appStoreGlobalActions.setOrdersToSyncAction,
    (state, { ordersToSync }) => ({
      ...state,
      ordersToSync,
    })
  ),

  on(
    appStoreGlobalActions.setCustomersToSyncAction,
    (state, { customersToSync }) => ({
      ...state,
      customersToSync,
    })
  ),

  on(
    appStoreGlobalActions.setNotificationCounterAction,
    (state, { notificationCounter }) => ({
      ...state,
      notificationCounter,
    })
  ),

  on(appStoreGlobalActions.decreaseNotificationCounterAction, (state) => ({
    ...state,
    notificationCounter:
      state.notificationCounter === 0 ? 0 : state.notificationCounter - 1,
  })),

  on(appStoreGlobalActions.increaseNotificationCounterAction, (state) => ({
    ...state,
    notificationCounter: state.notificationCounter + 1,
  })),

  on(
    appStoreGlobalActions.setNotificationCounterDtoAction,
    (state, { notificationCounterDto }) => ({
      ...state,
      notificationCounterDto,
    })
  ),

  on(
    appStoreGlobalActions.setNotificationCounterDtoTotalAction,
    (state, { value }) => {
      let total = state.notificationCounterDto.total + value;
      if (total < 0) {
        total = 0;
      }
      return {
        ...state,
        notificationCounterDto: {
          ...state.notificationCounterDto,
          total,
        },
      };
    }
  ),

  on(
    appStoreGlobalActions.setNotificationCounterDtoStarredAction,
    (state, { value }) => {
      let starred = state.notificationCounterDto.starred + value;
      if (starred < 0) {
        starred = 0;
      }
      return {
        ...state,
        notificationCounterDto: {
          ...state.notificationCounterDto,
          starred,
        },
      };
    }
  ),

  on(
    appStoreGlobalActions.setNotificationCounterDtoImportantAction,
    (state, { value }) => {
      let important = state.notificationCounterDto.important + value;
      if (important < 0) {
        important = 0;
      }
      return {
        ...state,
        notificationCounterDto: {
          ...state.notificationCounterDto,
          important,
        },
      };
    }
  ),

  on(
    appStoreGlobalActions.setNotificationCounterDtoDeletedAction,
    (state, { value }) => {
      let deleted = state.notificationCounterDto.deleted + value;
      if (deleted < 0) {
        deleted = 0;
      }
      return {
        ...state,
        notificationCounterDto: {
          ...state.notificationCounterDto,
          deleted,
        },
      };
    }
  )
);

export const appStoreGlobalReducer = (state: AppGlobal, action: Action): any =>
  appStoreGlobalRegister(state, action);
