import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { AppStorage } from './app.storage';

@Injectable({
  providedIn: 'root',
})
export class AppStorageService {
  constructor(protected storage: Storage) {
    this.init();
  }

  async init() {
    await this.storage.create();
  }

  get = async <T = any>(key: string): Promise<T> => {
    const res = await this.storage.get(key);
    if (!res) {
      return res;
    }

    if (res[0] === '[' || res[0] === '{') {
      return JSON.parse(res);
    }
    return res;
  };

  set = <T = any>(key: string, value: T): Promise<T> => {
    if (value != null && (typeof value === 'object' || Array.isArray(value))) {
      return this.storage.set(key, JSON.stringify(value));
    }
    return this.storage.set(key, value);
  };

  remove = (key: string): Promise<any> => this.storage.remove(key);

  clear = (): Promise<void> => this.storage.clear();

  clearOneByOne = async (afterAll: () => void) => {
    await this.storage.forEach((_, key, __) => {
      if (key !== AppStorage.CACHE_KEYS) {
        this.storage.remove(key).then(() => {});
      }
    });
    this.storage.length().then((size) => {
      if (size === 1) {
        afterAll();
      }
    });
  };
}
