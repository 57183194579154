import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { MenuItem } from 'primeng/api';
import { finalize } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { BaseComponent } from 'src/app/core/base/base.component';
import { CollaboratorFull } from 'src/app/models/collaborator';
import { SecurityUser } from 'src/app/models/security/security-user';
import { AppStorage } from 'src/app/core/utils/app.storage';
import { AppStorageService } from 'src/app/core/utils/app.storage.service';
import { LayoutService } from './service/app.layout.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss'],
})
export class AppTopBarComponent extends BaseComponent implements OnInit {
  menu: MenuItem[] = [];

  @ViewChild('menubutton') menuButton!: ElementRef;

  userInfo?: SecurityUser;
  collaborator?: CollaboratorFull;

  appointmentsToSync = 0;
  ordersToSync = 0;
  customersToSync = 0;

  rotationUnlocked = false;

  username = '';
  showPasswordTemporal = false;
  showPasswordChange = false;
  newPassword?: string;
  newPasswordConfirm?: string;
  passwordSaving = false;

  notificationsCounter?: number = 0;

  constructor(
    override store: Store<any>,
    override platform: Platform,
    public layoutService: LayoutService,
    protected cookie: CookieService,
    protected appStorageService: AppStorageService,
    protected appService: AppService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.store.select('global', 'user').subscribe({
      next: (user: SecurityUser) => {
        this.userInfo = user;
        this.username = user.username;
        this.showPasswordTemporal = user.isTemporal;
      },
    });

    this.store.select('global', 'notificationCounter').subscribe({
      next: (notificationCounter: number) => {
        this.notificationsCounter = notificationCounter;
      },
    });

    this.store.select('global', 'collaborator').subscribe({
      next: (collaborator) => {
        this.collaborator = collaborator;
      },
    });

    this.store.select('global', 'appointmentsToSync').subscribe({
      next: (appointmentsToSync) => {
        this.appointmentsToSync = appointmentsToSync;
      },
    });
    this.store.select('global', 'ordersToSync').subscribe({
      next: (ordersToSync) => {
        this.ordersToSync = ordersToSync;
      },
    });
    this.store.select('global', 'customersToSync').subscribe({
      next: (customersToSync) => {
        this.customersToSync = customersToSync;
      },
    });
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onLogoutClick = () => {
    this.layoutService.cleanRestCache();
    this.appStorageService.remove(AppStorage.SESSION).then(() => {
      window.location.reload();
    });
  };

  handleEnableScreenRotation = () => {
    if (!this.rotationUnlocked) {
      ScreenOrientation.unlock();
    } else {
      ScreenOrientation.lock({ orientation: 'portrait' });
    }
    this.rotationUnlocked = !this.rotationUnlocked;
  };

  handlePasswordChange = () => {
    this.passwordSaving = true;
    this.appService
      .changePassword(this.username, this.newPassword)
      .pipe(
        finalize(() => {
          this.passwordSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this.showPasswordTemporal = false;
          this.showPasswordChange = false;
          this.onLogoutClick();
        },
      });
  };

  get logo(): string {
    return '/assets/imgs/logo.png';
  }

  get totalToSync(): string {
    return `${
      this.appointmentsToSync + this.ordersToSync + this.customersToSync
    }`;
  }
}
